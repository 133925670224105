<template>
  <div>
    <section class="bg-grey bg bg-menu">
      <b-row class="content-wrapper row">
        <b-col
          xl="5"
          md="12"
        >
          <h2 class="mt-4 mb-xl-3 text-xl-left">
            Mi cuenta > <b>Recompensas</b>
          </h2>
        </b-col>
      </b-row>
    </section>

    <section class="bg-grey bg">
      <b-row class="content-wrapper row pt-5">
        <b-col
          class="d-block m-auto"
          lg="12"
        >
          <b-row>
            <b-col
              lg="12"
              xl="4"
            >
              <b-card class="px-5">
                <div
                  class="text-center"
                >
                  <b-avatar
                    class="avatar-size"
                    variant="primary"
                  />
                  <h2 class="mt-2">
                    {{ this.$store.state.account.user.name }} <!--todo: Poner el nombre de usuario-->
                  </h2>
                  <p>Distribuidor</p> <!--todo: Poner el nombre de usuario-->
                  <ul class="ul-links">
                    <li>
                      <a
                        href="/profile"
                        @click.prevent="$router.push({ name: 'profile-information'})"
                      >Mis datos</a>
                    </li>
                    <li>
                      <a
                        href="/all-orders"
                        @click.prevent="$router.push({ name: 'all-orders'})"
                      >Mis compras</a>
                    </li>
                    <li>
                      <a
                        href="/profile-rewards"
                        class="active"
                        @click.prevent="$router.push({ name: 'profile-rewards'})"
                      ><b>Recompensas</b></a>
                    </li>
                    <!--                    <li>
                      <a
                        href="/profile"
                        target="_blank"
                      >Favoritos</a>
                    </li>-->
                  </ul>
                </div>
              </b-card>
            </b-col>
            <b-col
              lg="12"
              xl="8"
            >
              <b-card>
                <b-row>
                  <b-col
                    xl="12"
                  >
                    <img
                      src="@/assets/images/icos/points.svg"
                      alt="browser"
                      class="mr-1"
                      height="80"
                    >
                    <h1 class="d-contet mb-4">
                      <b>{{ getUserPoints }} pts.</b>
                    </h1>
                    <hr>

<!--                    <p class="font-g">
                      Aumenta tus puntos <span class="text-primary">adquiriendo más de nuestros promocionales</span>, ¡podrás pagar con estos futuras compras!
                    </p>-->
                  </b-col>
                </b-row>
                <b-row class="d-block">
                  <b-col
                    lg="12"
                    xl="10"
                    class="d-block m-auto text-center pt-2"
                  >
                    <h2 class="title-products mt-1 mb-3">
                      Preguntas frecuentes
                    </h2>

                    <app-collapse
                      accordion
                      type="margin"
                      class="faq-home"
                    >

                      <app-collapse-item
                        class="faq-title"
                        title="¿Cómo puedo hacer un pedido en PromoShop?"
                      >
                        <ol>
                          <li>Accesa a la categoría de productos que te interesa</li>
                          <li>Una vez seleccionado el artículo a comprar, elige la marca y color que deseas adquirir</li>
                          <li>Presiona el botón "agregar al carrito"</li>
                          <li>En caso de querer seleccionar más productos, da clic a la categoría de tu interés para seguir agregándolos a tu compra</li>
                          <li>Una vez que estés listo para finalizar tu compra, haz clic en "ver en mi carrito"</li>
                          <li>Revisa los detalles de tu compra y da clic en "siguiente"</li>
                          <li>Selecciona o edita la dirección en donde quieres recibirlo</li>
                          <li>Da clic en "siguiente" y selecciona el método de pago</li>
                          <li>Da clic en "pagar"</li>
                          <li>Revisa en la bandeja de entrada de tu correo la confirmación de compra</li>
                        </ol>

                      </app-collapse-item>

                      <app-collapse-item title="¿Qué métodos de pago aceptan en Promoshop?">
                        <ul>
                          <li v-if="!isCentroAmerica">Tarjeta / Crédito / Débito / Oxxo a través de Conekta</li>
                          <li>Paga usando puntos</li>
                          <li v-if="!isCentroAmerica">Paga mediante SPEI</li>
                          <li>Paga mediante el centro de costos (método de pago solo disponible para personal staff de Clarios)</li>
                        </ul>
                      </app-collapse-item>

                      <app-collapse-item title="¿Cómo facturar mi compra?">
                        <ol>
                          <li>Ingresa a tus pedidos</li>
                          <li>Selecciona el pedido a facturar</li>
                          <li>Da clic en "Facturar"</li>
                          <li>
                            Llena el campo de datos con la siguiente información:
                            <ul style="list-style-type: lower-alpha;">
                              <li>Registro Federal de Contribuyentes (R.F.C.)</li>
                              <li>Nombre completo o razón social sin régimen de capital</li>
                              <li>Código Postal de su domicilio fiscal vigente</li>
                              <li>Régimen fiscal registrado ante las autoridades fiscales</li>
                            </ul>
                          </li>
                        </ol>
                        <p class="pl-2">
                          Dichos datos deben ser válidos y vigentes para poder emitir la factura y se pueden obtener en el documento digital que emite el SAT denominado: “Constancia de Situación Fiscal”.
                        </p>
                        <p class="pl-2">
                          En caso de que cualquier dato sea incorrecto o no coincida con los datos que están registrados ante las autoridades fiscales, no podrá emitirse la factura, pues son validados por el SAT como parte del proceso emisión en esta nueva versión 4.0.
                        </p>
                      </app-collapse-item>

                      <app-collapse-item title="¿Cada cuánto tiempo se realiza la carga de puntos?">
                        Se realizan cuatro cargas anuales de manera trimestral, cada una con una vigencia de seis meses. Cada vez que se realice una carga de puntos o la vigencia de los mismos se aproxime, se enviará un correo de notificación al usuario.
                      </app-collapse-item>

                      <app-collapse-item title="¿Los puntos son acumulables?">
                        Los puntos sí son acumulables, siempre y cuando estén dentro de la fecha de vigencia indicada.
                      </app-collapse-item>

                      <app-collapse-item title="¿Las compras con puntos se pueden facturar?">
                        No, unicamente las realizadas con pago de tarjeta o transferencia.
                      </app-collapse-item>

                      <app-collapse-item title="¿Cómo cancelar mi compra?">
                        Contacta a servicio al cliente para poder cancelar tu compra. Solamente puedes cancelar las compras que están pendientes de pago.
                      </app-collapse-item>

                      <app-collapse-item title="Si ya realicé mi pago, ¿a dónde puedo enviar mis datos?" v-if="!isCentroAmerica">
                        Puedes enviar tus datos a soporte@promoshopclarios.com o al whatsapp 6647060354 de Lunes a Viernes de 9 am a 5 pm
                      </app-collapse-item>

                      <app-collapse-item title="¿Puedo hacer la compra de varios productos a la vez?">
                        Efectivamente, no hay un límite de compra en cantidad o variedad.
                      </app-collapse-item>

                      <app-collapse-item title="¿Cuáles son los tiempos de entrega?">
                        <p class="pl-2">
                          El tiempo de entrega varía de acuerdo al producto, podrás encontrar esta información en la descripción del mismo.
                        </p>
                        <p class="pl-2" v-if="!isCentroAmerica">
                          El rango estimado va desde 15 a 40 días hábiles.
                        </p>
                      </app-collapse-item>

                      <app-collapse-item title="¿Cómo puedo hacer una solicitud especial? (variedad en tallas o marcas)">
                        Puedes enviar tu solicitud con las especifiaciones al correo <a v-if="isCentroAmerica" href="mailto:soporte@promoshopclarioscam.com">soporte@promoshopclarioscam.com</a> <a v-if="!isCentroAmerica" href="mailto:soporte@promoshopclarios.com">soporte@promoshopclarios.com</a> o al whatsapp
                        <a v-if="isCentroAmerica" href="https://wa.me/526647060354">+52 664 7060 354</a> <a v-if="!isCentroAmerica" href="https://wa.me/6647060354">664 7060 354</a> de Lunes a Viernes de 9 am a 5 pm.

                      </app-collapse-item>
                    </app-collapse>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { IS_CENTROAMERICA } from '@/config/app'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BCard,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
      search: '',
    }
  },
  computed: {
    isCentroAmerica() {
      return IS_CENTROAMERICA
    },
    getUserPoints() {
      if (!this.$store.state.account.user.points) {
        return 0
      }
      return this.$store.state.account.user.points
    },
  },
  created() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', this.contentWidth)
  },
  methods: {
    searchProduct() {
      this.$router.push({ name: 'products', params: { searchActive: 'true' } })
    },
  },
}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables.scss';
@import '~@core/scss/base/components/_variables-dark.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";

//New Arribals
.card-data{
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #979593;
  border-radius: 5px;
}
.title-card{
  padding: 10px;
  border-bottom: 1px solid #979593;
  background-color: #f2f2f2;
  border-radius: 5px 5px 0px 0px;
}
.body-card{
  padding: 10px;
}
.bg-menu {
  margin-top: -45px;
  background-color: #fff!important;
}
.header-navbar-shadow{
  background:none!important;
}
//New Arribals
.avatar-size{
  width: 100px;
  height: 100px;
  display: flex;
  margin: 0 auto;
}
.dark-layout{
  .card-data{
    background-color: $theme-dark-body-bg;
  }
  .ul-links{
    a{
      color: $theme-dark-body-color;
    }
  }
  .bg-menu{
    background-color: $theme-dark-card-bg!important;
  }
  .collapse-title{
    color: $theme-dark-headings-color!important;
  }
  .blockquote{
    background-color: $theme-dark-input-bg;
    border:solid 1px $theme-dark-headings-color;
  }
  .title-products{
    font-size: 30px;
    font-weight: 700;
    color: $theme-dark-headings-color!important;
  }
  .bg-grey{
    background-color: $theme-dark-body-bg;
  }
  .img-overlay {
    background-color: $theme-dark-input-bg;
  }
}
.bg {
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

}
.bg-grey{
   background-color: #f2f2f2;

 }

.content-wrapper {
  margin-left: 50vw;
  margin-right: 50vw;
  box-sizing: border-box;

  > * {
    box-sizing: content-box;
  }
}
.ul-links{
  list-style: none;
  text-align: left;
  a{
    color: $dark;
  }
  .active{
    color: $primary;
  }
}
.body-card{
  p{
    margin-bottom: 0px;
  }
}
.btn-detail{
  background-color: rgba(74,34,104,1) !important;
  border: none;
}
.btn-gradient-promo{
  background: rgb(246,162,27);
  background: -moz-linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  background: linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6a21b",endColorstr="#4a2268",GradientType=1);
  border: none;
}
.d-contet{
  display: inline-block;
  h1{
    font-size: 40px;
    padding-top: 50px;
  }
}
.font-g{
  color: rgba(74,34,104,1)!important;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
}
</style>
